<template>
  <div>
    <b-overlay
      :show="isMaintenanceRenewOverlayActiveProp"
      no-wrap
    >
      <template v-slot:overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-left p-1"
        >
          <b-overlay
            :show="loading"
            no-wrap
          />
          <h4>{{ $t('maintenance.Renew maintenance?') }}</h4>
          <!-- BODY -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              @submit.prevent="handleSubmit(onOk)"
            >
              <b-form-row>
                <b-col
                  cols="12"
                  lg="12"
                >
                  <!-- Name -->
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-group
                      label="name"
                      label-for="name"
                    >
                      <template v-slot:label>
                        {{ $t("maintenances.denomination") }} <code>*</code>
                      </template>
                      <b-form-input
                        id="name"
                        v-model="maintenance.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <!-- expire -->
                <b-col
                  cols="12"
                  lg="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="expire"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('maintenances.expire_date')"
                      label-for="expire"
                    >
                      <template v-slot:label>
                        {{ $t("maintenances.expire_date") }} <code>*</code>
                      </template>
                      <b-form-datepicker
                        v-model="maintenance.expire"
                        :initial-date="maintenance.expire"
                        aria-placeholder="DD/MM/YYY"
                        left
                        calendar-width="100%"
                        locale="es-ES"
                        start-weekday="1"
                        placeholder=""
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- renew -->
                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('maintenances.renew_period')"
                  >
                    <b-form-group
                      :label="$t('maintenances.renew_period')"
                      label-for="renew"
                    >
                      <b-form-input
                        id="renew"
                        v-model="maintenance.renew"
                        autofocus
                        trim
                        placeholder=""
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- price -->
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    :label="$t('maintenances.price')"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      v-model="maintenance.price"
                      class="form-control"
                      autofocus
                      trim
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>

              <!-- comments -->
              <b-form-group
                :label="$t('comment')"
                label-for="comments"
              >
                <b-form-textarea
                  id="comments"
                  v-model="maintenance.comments"
                  autofocus
                  trim
                  placeholder=""
                />
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ $t('maintenance.Renew') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="onCancel"
                >
                  {{ $t('maintenance.No renew') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormRow, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BRow, BCol, BFormDatepicker, BPopover, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  computed, ref, onMounted, onBeforeMount, onUnmounted, watch, toRef,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import _ from 'lodash'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormRow,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    BPopover,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isMaintenanceRenewOverlayActive',
    event: 'update:is-maintenance-renew-overlay-active',
  },
  props: {
    isMaintenanceRenewOverlayActive: {
      type: Boolean,
      required: true,
    },
    maintenance: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const maintenanceProp = toRef(props, 'maintenance')

    const isMaintenanceRenewOverlayActiveProp = toRef(props, 'isMaintenanceRenewOverlayActive')

    const {
      getValidationState,
    } = formValidation(maintenanceProp.value)

    const onOk = () => {
      emit('onOk')
      emit('update:maintenance', maintenanceProp)
      emit('update:is-maintenance-renew-overlay-active', false)
    }

    const onCancel = () => {
      emit('onCancel')
      emit('update:is-maintenance-renew-overlay-active', false)
    }

    return {
      onOk,
      onCancel,
      maintenanceProp,
      isMaintenanceRenewOverlayActiveProp,
      getValidationState,
      loading,
    }
  },
}
</script>

<style lang="scss">
</style>
