<template>
  <div>
    <maintenance-list-edit
      :maintenance="maintenance"
      :is-maintenance-handler-sidebar-active.sync="isMaintenanceHandlerSidebarActive"
      @refetch-data="refetchData"
    />
    <maintenance-list-add-new
      :is-add-new-maintenance-sidebar-active.sync="isAddNewMaintenanceSidebarActive"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMaintenanceListTable"
        class="position-relative"
        :items="fetchMaintenances"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="handleMaintenanceClick(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(type)="row">
          <b-badge v-if="(row.item.type === 'Corrective')" class="bg-gradient-danger"> {{ $t('maintenances.' + (row.item.type)) }}</b-badge>
          <b-badge v-else class="bg-gradient-warning"> {{ $t('maintenances.' + (row.item.type || 'Preventive')) }}</b-badge>
        </template>

        <template #cell(expire)="data">
          {{ dateFormat(data.value) }}
        </template>

        <template #cell(done)="data">
          <b-avatar v-if="(data.value)"
                    v-b-tooltip.hover.right="`${$t('Completed')}: ${dateFormat(data.value)}`"
                    pill
                    variant="light-success"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="14"
            />
          </b-avatar>
          <b-avatar v-else
                    pill
                    variant="warning"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="14"
            />
          </b-avatar>
        </template>

        <!-- Column: users (Team) -->
        <template #cell(users)="row">
          <users-group-simple
            v-if="row.item.users"
            :type="`maintenances_table_${row.item.id}`"
            :users-selected="row.item.users"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('records') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMaintenances"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
 ref, onUnmounted, toRef, watch,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersGroupSimple from '@/views/apps/user/UsersGroupSimple.vue'
import MaintenanceListEdit from "@/views/apps/maintenance/maintenances-list/MaintenanceListEdit.vue"
import useMaintenancesList from './useMaintenancesList'
import MaintenanceListAddNew from './MaintenanceListAddNew.vue'
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    MaintenanceListAddNew,
    MaintenanceListEdit,
    UsersGroupSimple,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
    directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const isAddNewMaintenanceSidebarActive = ref(false)
    const isMaintenanceHandlerSidebarActive = ref(false)

    const maintenance = ref(null)

    const handleMaintenanceClick = maintenanceData => {
      maintenance.value = maintenanceData
      isMaintenanceHandlerSidebarActive.value = true
    }

    const {
      fetchMaintenances,
      tableColumns,
      perPage,
      currentPage,
      totalMaintenances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaintenanceListTable,
      refetchData,
    } = useMaintenancesList()

    const { dateFormat } = useGeneralView()

    return {

      // Sidebar
      isAddNewMaintenanceSidebarActive,

      fetchMaintenances,
      tableColumns,
      perPage,
      currentPage,
      totalMaintenances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaintenanceListTable,
      refetchData,

      // Filter
      avatarText,

      maintenance,
      isMaintenanceHandlerSidebarActive,
      handleMaintenanceClick,
      dateFormat,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
