<template>
  <b-sidebar
    id="add-new-maintenance-sidebar"
    :visible="isAddNewMaintenanceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="50em"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-maintenance-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t("maintenances.Add Maintenance") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- project_id -->
          <validation-provider
            v-if="!propProjectId"
            #default="validationContext"
            name="project_id"
            rules="required"
          >
            <b-form-group
              label="project_id"
              label-for="project_id"
            >
              <b-form-input
                id="client_id"
                v-model="maintenanceData.project_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-row>
            <b-col
              cols="12"
              lg="8"
            >
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="name"
                  label-for="name"
                >
                  <template v-slot:label>
                    {{ $t("maintenances.denomination") }} <code>*</code>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="maintenanceData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              lg="4"
            >
              <!-- Type -->
              <validation-provider
                #default="validationContext"
                name="Type"
                rules="required"
              >
                <b-form-group
                  label="type"
                  label-for="type"
                >
                  <template v-slot:label>
                    {{ $t("maintenances.type") }} <code>*</code>
                  </template>
                  <b-form-select
                    v-model="maintenanceData.type"
                    :options="maintenanceTypes"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <!-- comments -->
          <b-form-group
            :label="$t('comment')"
            label-for="comments"
          >
            <b-form-textarea
              id="comments"
              v-model="maintenanceData.comments"
              autofocus
              trim
              placeholder=""
            />
          </b-form-group>

          <b-form-row>
            <!-- expire -->
            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="expire"
              >
                <b-form-group
                  :label="$t('maintenances.expire_date')"
                  label-for="expire"
                  rules="required"
                >
                  <template v-slot:label>
                    {{ $t("maintenances.expire_date") }} <code>*</code>
                  </template>
                  <b-form-datepicker
                    v-model="maintenanceData.expire"
                    :initial-date="maintenanceData.expire"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- renew -->
            <b-col
              cols="12"
              lg="5"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('maintenances.renew_period')"
              >
                <b-form-group
                  :label="$t('maintenances.renew_period')"
                  label-for="renew"
                >
                  <template v-slot:label>
                    {{ $t("maintenances.renew_period") }}
                  </template>
                  <b-form-input
                    id="renew"
                    v-model="maintenanceData.renew"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- price -->
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group
                :label="$t('maintenances.price')"
                label-for="price"
              >
                <cleave
                  id="price"
                  v-model="maintenanceData.price"
                  class="form-control"
                  autofocus
                  trim
                  placeholder=""
                  :options="options.number"
                />
              </b-form-group>
            </b-col>
          </b-form-row>

          <div>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <h4>
              {{ $t('team') }}
            </h4>
            <maintenances-users-group
              type="Maintenance"
              :users-selected.sync="maintenanceData.users"
              :users-selected-array.sync="usersMaintenanceSelected"
            />
          </div>

          <b-col cols="12">
            <hr>
          </b-col>

          <!-- TASKS -->
          <div>
            <h4>
              {{ $t('tasks.title') }}
            </h4>
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in maintenanceData.items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Item Name -->
              <b-col
                cols="12"
                md="8"
              >
                <validation-provider
                  #default="validationContext"
                  name="expire"
                  rules="required"
                >
                  <b-form-group
                    label="Item Name"
                    label-for="item-name"
                  >
                    <template v-slot:label>
                      {{ $t("tasks.task") }} <code>*</code>
                    </template>
                    <b-form-input
                      id="item-name"
                      v-model="item.name"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- price -->
              <b-col
                md="2"
                sm="8"
              >
                <b-form-group
                  label="Price"
                  label-for="price"
                >
                  <cleave
                    id="price"
                    v-model="item.price"
                    class="form-control"
                    autofocus
                    trim
                    placeholder=""
                    :options="options.number"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                md="2"
                sm="4"
                class="mb-50"
              >
                <b-button
                  variant="outline-danger"
                  class="mt-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              size="sm"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('tasks.Add Task') }}</span>
            </b-button>
          </div>

          <b-col cols="12">
            <hr>
          </b-col>

          <!-- MATERIALS -->
          <div>
            <h4>
              {{ $t('materials.title') }}
            </h4>
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in maintenanceData.materials"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Item Name -->
              <b-col
                cols="12"
                md="8"
              >
                <validation-provider
                  #default="validationContext"
                  name="expire"
                  rules="required"
                >
                  <b-form-group
                    label="Item Name"
                    label-for="material-name"
                  >
                    <template v-slot:label>
                      {{ $t("materials.material") }} <code>*</code>
                    </template>

                    <b-form-select
                      v-model="item.name"
                      :options="materialsList"
                    />

                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- quantity -->
              <b-col
                md="2"
                sm="8"
              >
                <b-form-group
                  :label="$t('materials.quantity')"
                  label-for="quantity"
                >
                  <cleave
                    id="quantity"
                    v-model="item.quantity"
                    class="form-control"
                    autofocus
                    trim
                    placeholder=""
                    :options="options.number"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                md="2"
                sm="4"
                class="mb-50"
              >
                <b-button
                  variant="outline-danger"
                  class="mt-2"
                  @click="removeMaterial(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              size="sm"
              @click="repeatMaterial"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('materials.Add Material') }}</span>
            </b-button>
          </div>

          <b-col cols="12">
            <hr>
          </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormRow, BFormInput, BFormSelect, BFormInvalidFeedback, BButton, BFormTextarea, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
 computed, ref, onMounted, onBeforeMount, onUnmounted,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Cleave from 'vue-cleave-component'
import router from '@/router'
import store from '@/store'
import MaintenancesUsersGroup from '@/views/apps/user/UsersGroup.vue'
import useMaintenanceView from "@/views/apps/maintenance/maintenances-view/useMaintenanceView"
import useProjectView from "@/views/apps/project/projects-view/useProjectView"
import useGeneralView from "@/views/useGeneralView"
import _ from "lodash"
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormRow,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    MaintenancesUsersGroup,
    vSelect,
    Cleave,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isAddNewMaintenanceSidebarActive',
    event: 'update:is-add-new-maintenance-sidebar-active',
  },
  props: {
    isAddNewMaintenanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    project_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  setup(props, { emit }) {
    const propProjectId = (props.project_id && props.project_id !== undefined) ? computed(() => props.project_id) : ''

    const blankMaintenanceData = {
      project_id: propProjectId.value,
      name: '',
      type: '',
      comments: '',
      renew: null,
      price: '',
      expire: '',
      done: '',
      items: [{
        id: 0,
        price: '',
        name: '',
        comments: '',
      }],
      users: [],
      materials: [{
        id: 0,
        name: '',
        quantity: '',
      }],
    }

    const maintenanceTypes = [
      { value: 'Corrective', text: i18n.t("maintenances.Corrective") },
      { value: 'Preventive', text: i18n.t('maintenances.Preventive') },
    ]

    const usersMaintenanceSelected = ref(null)

    const maintenanceData = ref(JSON.parse(JSON.stringify(blankMaintenanceData)))
    const resetMaintenanceData = () => {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

    const { addMaintenance, addUserMaintenance } = useMaintenanceView()
    const { fetchProjectUsers, fetchProjectMaintenances, fetchProjectActivity, fetchMaterialsSelect } = useProjectView()
    const { fetchNotifications } = useGeneralView()

    const materialsList = ref([])

    const nextTodoId = ref(0)

    const repeatAgain = (() => {
      maintenanceData.value.items.push({
        // eslint-disable-next-line no-plusplus
        id: ++nextTodoId.value,
      })
    })

    const removeItem = (index => {
      maintenanceData.value.items.splice(index, 1)
    })

    const repeatMaterial = (() => {
      maintenanceData.value.materials.push({
        // eslint-disable-next-line no-plusplus
        id: ++nextTodoId.value,
      })
    })

    const removeMaterial = (index => {
      maintenanceData.value.materials.splice(index, 1)
    })

    const onSubmit = async () => {
      const queryParams = _.clone(maintenanceData.value)
      queryParams.items = JSON.stringify(queryParams.items)
      queryParams.materials = JSON.stringify(queryParams.materials)
      queryParams.users = usersMaintenanceSelected.value

      await addMaintenance(queryParams)
        .then(async response => {
          await addUserMaintenance({ project_id: queryParams.project_id, id: response.data.id, users: queryParams.users })

          fetchNotifications()

          if (store.hasModule('project')) {
            fetchProjectMaintenances({ id: queryParams.project_id })
            fetchProjectUsers({ id: queryParams.project_id })
            fetchProjectActivity({ id: queryParams.project_id })
          }
          emit('refetch-data')
          emit('update:is-add-new-maintenance-sidebar-active', false)
          // router.push({ name: 'maintenances-edit', params: { id: maintenanceId } })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMaintenanceData)

    onMounted(async () => {
      materialsList.value = await fetchMaterialsSelect()
    })

    return {
      propProjectId,
      maintenanceData,
      maintenanceTypes,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      repeatAgain,
      repeatMaterial,
      removeItem,
      removeMaterial,
      usersMaintenanceSelected,
      materialsList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
 overflow: hidden;
 transition: .35s height;
}
</style>
