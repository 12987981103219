import {
ref, reactive, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useMaintenanceView() {
  const addMaintenance = params => store.dispatch('model/maintenance/addMaintenance', params)
          .then(response => response)
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })

  const editMaintenance = params => store.dispatch('model/maintenance/editMaintenance', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const renewMaintenance = params => store.dispatch('model/maintenance/renewMaintenance', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const deleteMaintenance = params => store.dispatch('model/maintenance/deleteMaintenance', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const addUserMaintenance = async params => store.dispatch('model/maintenance/addMaintenanceUsers', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const deleteMaintenanceTask = params => store.dispatch('model/maintenance/deleteMaintenanceTask', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const updateMaintenanceTask = params => store.dispatch('model/maintenance/updateMaintenanceTask', params)
      .then(response => response)
      .catch(error => {
          if (error.response.status === 404) {
              //
          }
      })

  const deleteMaintenanceMaterial = params => store.dispatch('model/maintenance/deleteMaintenanceMaterial', params)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 404) {
        //
      }
    })

  const resolveUserMaintenanceStatus = status => {
      if (status === 'danger') return 'Terminado'
      if (status === 'success') return 'En proceso'

      return 'No especificado'
  }

return {
  addMaintenance,
  editMaintenance,
  deleteMaintenance,
  addUserMaintenance,
  deleteMaintenanceTask,
  updateMaintenanceTask,
  deleteMaintenanceMaterial,
  resolveUserMaintenanceStatus,
  renewMaintenance,
}
}
