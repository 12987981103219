<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
    />
    <b-sidebar
      id="is-maintenance-handler-sidebar-active"
      :visible="isMaintenanceHandlerSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      width="50em"
      @change="(val) => $emit('update:is-maintenance-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t("maintenances.Edit Maintenance") }}
          </h5>
          <div>
            <b-button
              v-if="(maintenanceData.done)"
              ref="button"
              variant="outline-success"
              class="mr-1"
            >
              <feather-icon
                v-show="maintenanceData.id"
                icon="CheckSquareIcon"
                class="cursor-pointer"
              /> {{ $t('Completed') }}
            </b-button>
            <b-button
              v-if="(!maintenanceData.done)"
              id="complete-maintenance-button"
              ref="button"
              variant="outline-primary"
              class="mr-1"
              @click="onCompleteMaintenance"
            >
              <feather-icon
                v-show="maintenanceData.id"
                icon="CheckSquareIcon"
                class="cursor-pointer"
              /> {{ $t('Finish') }}
            </b-button>
            <b-button
              id="popover-delete"
              ref="button"
              variant="outline-danger"
            >
              <feather-icon
                v-show="maintenanceData.id"
                icon="TrashIcon"
                class="cursor-pointer text-danger"
              /> {{ $t('delete') }}
            </b-button>
            <b-popover
              ref="popover"
              target="popover-delete"
              triggers="click"
              placement="left"
              container="popover-delete"
            >
              <div>
                <p>{{ $t('Are you sure?') }}</p>
                <b-button
                  size="sm"
                  variant="link"
                  class="mr-1"
                >
                  {{ $t('Cancel') }}
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="onDelete(); hide();"
                >
                  {{ $t('Delete') }}
                </b-button>
              </div>
            </b-popover>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- project_id -->
            <validation-provider
              v-if="(!maintenanceData.project_id && !propProjectId)"
              #default="validationContext"
              name="project_id"
              rules="required"
            >
              <b-form-group
                label="project_id"
                label-for="project_id"
              >
                <b-form-input
                  id="client_id"
                  v-model="maintenanceData.project_id"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-row>
              <b-col
                cols="12"
                lg="8"
              >
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    label="name"
                    label-for="name"
                  >
                    <template v-slot:label>
                      {{ $t("maintenances.denomination") }} <code>*</code>
                    </template>
                    <b-form-input
                      id="name"
                      v-model="maintenanceData.name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <!-- Type -->
                <validation-provider
                  #default="validationContext"
                  name="Type"
                  rules="required"
                >
                  <b-form-group
                    label="type"
                    label-for="type"
                  >
                    <template v-slot:label>
                      {{ $t("maintenances.type") }} <code>*</code>
                    </template>
                    <b-form-select
                      v-model="maintenanceData.type"
                      :options="maintenanceTypes"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>

            <!-- comments -->
            <b-form-group
              :label="$t('comment')"
              label-for="comments"
            >
              <b-form-textarea
                id="comments"
                v-model="maintenanceData.comments"
                trim
                placeholder=""
              />
            </b-form-group>

            <b-form-row>
              <!-- expire -->
              <b-col
                cols="12"
                lg="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="expire"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('maintenances.expire_date')"
                    label-for="expire"
                  >
                    <template v-slot:label>
                      {{ $t("maintenances.expire_date") }} <code>*</code>
                    </template>
                    <b-form-datepicker
                      v-model="maintenanceData.expire"
                      :initial-date="maintenanceData.expire"
                      aria-placeholder="DD/MM/YYY"
                      left
                      calendar-width="100%"
                      locale="es-ES"
                      start-weekday="1"
                      placeholder=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- renew -->
              <b-col
                cols="12"
                lg="5"
              >
                <b-form-group
                  :label="$t('maintenances.renew_period')"
                  label-for="renew"
                >
                  <b-form-input
                    id="renew"
                    v-model="maintenanceData.renew"
                    trim
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <!-- price -->
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('maintenances.price')"
                  label-for="price"
                >
                  <b-form-input
                    id="maintenancePrice"
                    v-model="maintenanceData.price"
                    trim
                    placeholder=""
                    class="text-right"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <div>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <h4>
                {{ $t('team') }}
              </h4>
              <maintenances-users-group
                type="MaintenanceEdit"
                :users-selected.sync="maintenanceData.users"
                :users-selected-array.sync="usersMaintenanceSelected"
              />
            </div>

            <!-- TASKS -->
            <div>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <h4>
                {{ $t('tasks.title') }}
              </h4>
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in maintenanceData.tasks"
                :id="item.id"
                :key="`t_${index}`"
                ref="tasks"
              >
                <!-- Item Name -->
                <b-col
                  cols="12"
                  md="8"
                  class="d-flex flex-row justify-content-start align-items-center"
                >
                  <b-form-checkbox
                    :checked="(item.done) ? true : false"
                    @click.native.stop
                    @change="taskDoneClicked(item.id, $event)"
                  />

                  <b-form-input
                    v-model="item.name"
                    type="text"
                    plaintext
                    disabled
                  />
                </b-col>

                <!-- price -->
                <b-col
                  md="2"
                  sm="8"
                >
                  <b-form-input
                    v-model="item.price"
                    type="text"
                    plaintext
                    disabled
                    class="text-right"
                  />
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  sm="4"
                  class="mb-50"
                >
                  <b-button
                    :id="`popover-delete-task_${item.id}`"
                    ref="button"
                    variant="outline-danger"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-popover
                    :ref="`popover-delete-task_${item.id}`"
                    :target="`popover-delete-task_${item.id}`"
                    triggers="click"
                    placement="left"
                    :container="`popover-delete-task_${item.id}`"
                  >
                    <div>
                      <p>{{ $t('Are you sure?') }}</p>
                      <b-button
                        size="sm"
                        block
                        variant="danger"
                        @click="removeTask(index, item.id)"
                      >
                        {{ $t('Delete') }}
                      </b-button>
                      <b-button
                        size="sm"
                        block
                        variant="link"
                      >
                        {{ $t('Cancel') }}
                      </b-button>
                    </div>
                  </b-popover>
                </b-col>
              </b-row>
              <b-row
                v-for="(item, index) in newTasks"
                :id="item.id"
                :key="index"
                ref="row"
              >
                <!-- Item Name -->
                <b-col
                  cols="12"
                  md="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="expire"
                    rules="required"
                  >
                    <b-form-group
                      label="Item Name"
                      label-for="item-name"
                    >
                      <template v-slot:label>
                        {{ $t("tasks.task name") }} <code>*</code>
                      </template>
                      <b-form-input
                        id="item-name"
                        v-model="item.name"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- price -->
                <b-col
                  md="2"
                  sm="8"
                >
                  <b-form-group
                    label="Price"
                    label-for="price"
                  >
                    <cleave
                      id="price"
                      v-model="item.price"
                      class="form-control"
                      trim
                      placeholder=""
                      :options="options.number"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  sm="4"
                  class="mb-50"
                >
                  <b-button
                    variant="outline-danger"
                    class="mt-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                size="sm"
                @click="repeatTask"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('tasks.Add Task') }}</span>
              </b-button>
            </div>

            <!-- MATERIALS -->
            <div>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <h4>
                {{ $t('materials.title') }}
              </h4>
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in maintenanceData.materials"
                :id="item.id"
                :key="`t_${index}`"
                ref="tasks"
              >
                <!-- Item Name -->
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex flex-row justify-content-start align-items-center"
                >
                  <b-form-checkbox
                    :checked="(item.checked) ? true : false"
                    @click.native.stop
                    @change="materialCheckedClicked(item.id, $event)"
                  />
                  {{ item.name + ' (' + item.quantity + ')' }}
                </b-col>

                <!-- Item Comments -->
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex flex-row justify-content-start align-items-center"
                >
                  {{ item.comments }}
                </b-col>

                <!-- price -->
                <b-col
                  md="2"
                  sm="8"
                >
                  <b-form-input
                    v-model="item.price"
                    type="text"
                    plaintext
                    disabled
                    class="text-right"
                  />
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  sm="4"
                  class="mb-50"
                >
                  <b-button
                    :id="`popover-delete-material_${item.id}`"
                    ref="button"
                    variant="outline-danger"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                  </b-button>
                  <b-popover
                    :ref="`popover-delete-material_${item.id}`"
                    :target="`popover-delete-material_${item.id}`"
                    triggers="click"
                    placement="left"
                    :container="`popover-delete-material_${item.id}`"
                  >
                    <div>
                      <p>{{ $t('Are you sure?') }}</p>
                      <b-button
                        size="sm"
                        block
                        variant="danger"
                        @click="removeMaterial(index, item.id)"
                      >
                        {{ $t('Delete') }}
                      </b-button>
                      <b-button
                        size="sm"
                        block
                        variant="link"
                      >
                        {{ $t('Cancel') }}
                      </b-button>
                    </div>
                  </b-popover>
                </b-col>
              </b-row>

              <!-- Add material -->
              <b-row
                v-for="(item, index) in newMaterials"
                :id="item.id"
                :key="index"
                ref="row"
              >
                <!-- Item Name -->
                <b-col
                  cols="8"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="expire"
                    rules="required"
                  >
                    <b-form-group
                      label="Item Name"
                      label-for="item-name"
                    >
                      <template v-slot:label>
                        {{ $t("materials.material") }} <code>*</code>
                      </template>
                      <b-form-select
                        v-model="item.name"
                        :options="materialsList"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- quantity -->
                <b-col
                  md="2"
                  sm="8"
                >
                  <b-form-group
                    :label="$t('materials.quantity')"
                    label-for="quantity"
                  >
                    <cleave
                      id="quantity"
                      v-model="item.quantity"
                      class="form-control text-right"
                      trim
                      placeholder="0"
                      :options="options.number"
                      size="sm"
                    />
                  </b-form-group>
                </b-col>

                <!-- price -->
                <b-col
                  md="2"
                  sm="8"
                >
                  <b-form-group
                    :label="$t('materials.price')"
                    label-for="price"
                  >
                    <cleave
                      id="price"
                      v-model="item.price"
                      class="form-control text-right"
                      trim
                      placeholder="0"
                      :options="options.number"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  sm="4"
                  class="mb-50"
                >
                  <b-button
                    variant="outline-danger"
                    class="mt-2"
                    @click="removeMaterialItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                size="sm"
                @click="repeatMaterial"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('materials.Add Material') }}</span>
              </b-button>
            </div>

            <b-col cols="12">
              <hr>
            </b-col>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Save changes') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div class="mt-1">
              <i v-if="(maintenanceData.done)" class="color-grey">* {{ $t('Maintenance already finished') }}</i>
            </div>
          </b-form>
        </validation-observer>

        <maintenance-complete-overlay
          :maintenance="maintenanceData"
          :is-maintenance-complete-overlay-active.sync="isMaintenanceCompleteOverlayActive"
          @onOk="onFinishMaintenance"
        />
        <maintenance-renew-overlay
          :maintenance.sync="maintenanceRenewData"
          :is-maintenance-renew-overlay-active.sync="isMaintenanceRenewOverlayActive"
          @onOk="onRenewMaintenance"
          @onCancel="onNoRenewMaintenance"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormRow, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BRow, BCol, BFormDatepicker, BPopover, BFormCheckbox, BOverlay, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  computed, ref, onMounted, onBeforeMount, onUnmounted, watch, toRef, toRefs, onUpdated,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Cleave from 'vue-cleave-component'
import _ from 'lodash'
import {
 format, parse, date, duration, add,
} from 'date-fns'
import router from '@/router'
import store from '@/store'
import MaintenancesUsersGroup from '@/views/apps/user/UsersGroup.vue'
import useMaintenanceView from '@/views/apps/maintenance/maintenances-view/useMaintenanceView'
import useProjectView from '@/views/apps/project/projects-view/useProjectView'
import useGeneralView from '@/views/useGeneralView'
import MaintenanceCompleteOverlay from '@/views/apps/maintenance/maintenances-list/MaintenanceCompleteOverlay.vue'
import MaintenanceRenewOverlay from '@/views/apps/maintenance/maintenances-list/MaintenanceRenewOverlay.vue'
import i18n from "@/libs/i18n"

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormRow,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    MaintenancesUsersGroup,
    MaintenanceCompleteOverlay,
    MaintenanceRenewOverlay,
    BPopover,
    BFormSelect,
    vSelect,
    Cleave,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isMaintenanceHandlerSidebarActive',
    event: 'update:is-maintenance-handler-sidebar-active',
  },
  props: {
    isMaintenanceHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    project_id: {
      type: Number,
      required: false,
    },
    maintenance: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const propProjectId = (props.project_id && props.project_id !== undefined) ? computed(() => props.project_id) : ''

    const blankMaintenanceData = {
      project_id: propProjectId.value,
      name: '',
      comments: '',
      type: '',
      renew: '',
      price: null,
      expire: '',
      done: '',
      tasks: [],
      users: [],
      materials: [],
    }

    const usersMaintenanceSelected = ref(null)
    const maintenanceProp = toRefs(props).maintenance
    const newTasks = ref([])
    const newMaterials = ref([])

    const maintenanceTypes = [
      { value: 'Corrective', text: i18n.t("maintenances.Corrective") },
      { value: 'Preventive', text: i18n.t('maintenances.Preventive') },
    ]

    const isMaintenanceCompleteOverlayActive = ref(false)
    const isCompleteBtnActive = ref(false)
    const isMaintenanceRenewOverlayActive = ref(false)

    const maintenanceData = ref(maintenanceProp.value ? maintenanceProp.value : blankMaintenanceData)
    const maintenanceRenewData = ref(blankMaintenanceData)

    const resetMaintenanceData = () => {
      maintenanceData.value = maintenanceProp.value
    }

    watch(maintenanceData, newValue => {
      // console.log(props.maintenance, maintenanceData.value)
      const openTasks = _.find(maintenanceData.value.tasks, ['done', false]).length
      if (openTasks === 0 && isCompleteBtnActive.value === false) {
        isMaintenanceCompleteOverlayActive.value = true
      }
      maintenanceRenewData.value = newValue
    })

    onUpdated(() => {
        resetMaintenanceData()
    })

    const {
     editMaintenance, addUserMaintenance, deleteMaintenance, deleteMaintenanceTask, updateMaintenanceTask, renewMaintenance, deleteMaintenanceMaterial,
    } = useMaintenanceView()
    const {
     fetchProjectUsers, fetchProjectMaintenances, fetchProjectActivity, fetchMaterialsSelect, checkMaterial, taskDone,
    } = useProjectView()
    const { fetchNotifications } = useGeneralView()

    const materialsList = ref([])
    const nextTodoId = ref(newTasks.value.length)
    const nextMaterialId = ref(newMaterials.value.length)

    const repeatTask = (() => {
      newTasks.value.push({
        // eslint-disable-next-line no-plusplus
        id: ++nextTodoId.value,
      })
    })

    const removeItem = (index => {
      newTasks.value.splice(index, 1)
    })

    const resetNewTasks = () => {
      newTasks.value = []
    }

    const repeatMaterial = (() => {
      newMaterials.value.push({
        // eslint-disable-next-line no-plusplus
        id: ++nextMaterialId.value,
      })
    })

    const removeMaterialItem = (index => {
      newMaterials.value.splice(index, 1)
    })

    const resetNewMaterials = () => {
      newMaterials.value = []
    }

    const removeMaterial = async (index, id) => {
      await deleteMaintenanceMaterial({ assigned_id: maintenanceData.value.materials[index].id })

      maintenanceData.value.materials.splice(index, 1)

      if (store.hasModule('project')) {
        fetchProjectMaintenances({ id: maintenanceData.value.project_id })
      }

      emit('refetch-data')
    }

    const removeTask = async (index, id) => {
      await deleteMaintenanceTask({ project_id: maintenanceData.value.project_id, id: maintenanceData.value.id, task_id: id })
      maintenanceData.value.tasks.splice(index, 1)

      if (store.hasModule('project')) {
        fetchProjectMaintenances({ id: maintenanceData.value.project_id })
      }

      emit('refetch-data')
    }

    const onCompleteMaintenance = async () => {
      isCompleteBtnActive.value = true
      isMaintenanceCompleteOverlayActive.value = true
    }

    const onFinishMaintenance = async () => {
      loading.value = true
      maintenanceData.value.tasks.forEach(task => {
        // update maintenanceTask done => now
        if (!task.done) {
          task.done = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
          updateMaintenanceTask({
            project_id: maintenanceData.value.project_id,
            id: maintenanceData.value.id,
            task,
          })
        }
      })

      // update maintenance done => now   *No pasar ni tasks ni usuarios
      const queryParams = _.clone(maintenanceData.value)
      queryParams.done = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      delete queryParams.tasks
      delete queryParams.materials
      delete queryParams.users

      await editMaintenance(queryParams).then(async response => {
        maintenanceData.value.done = response.data.done
        fetchNotifications()
      })

      loading.value = false

      // Renew maintenance
      if (maintenanceData.value.type === 'Preventive') {
          // overlay con form de renovación
        maintenanceRenewData.value = _.clone(queryParams)
        maintenanceRenewData.value.expire = format(add(parse(queryParams.expire, 'yyyy-MM-dd', new Date()), { days: _.toNumber(queryParams.renew) }), 'yyyy-MM-dd')
        isMaintenanceCompleteOverlayActive.value = false
        isMaintenanceRenewOverlayActive.value = true
      } else {
        emit('refetch-data')
        emit('update:is-maintenance-handler-sidebar-active', false)
      }
    }

    const onSubmit = async () => {
      loading.value = true
      const queryParams = _.clone(maintenanceData.value)
      queryParams.tasks = JSON.stringify(newTasks.value)
      queryParams.materials = JSON.stringify(newMaterials.value)
      queryParams.users = usersMaintenanceSelected.value

      await editMaintenance(queryParams)
        .then(async response => {
          resetNewTasks()
          resetNewMaterials()
          await addUserMaintenance({ project_id: queryParams.project_id, id: response.data.id, users: queryParams.users })

          fetchNotifications()

          if (store.hasModule('project')) {
            fetchProjectMaintenances({ id: queryParams.project_id })
            fetchProjectUsers({ id: queryParams.project_id })
            fetchProjectActivity({ id: queryParams.project_id })
          }
          emit('refetch-data')
          emit('update:is-maintenance-handler-sidebar-active', false)
        })

      loading.value = false
    }

    const onRenewMaintenance = async () => {
      loading.value = true
      const queryParams = _.clone(maintenanceRenewData.value)
      delete queryParams.tasks
      delete queryParams.materials
      delete queryParams.users
      await renewMaintenance(queryParams)
          .then(async response => {
            fetchNotifications()

            if (store.hasModule('project')) {
              fetchProjectMaintenances({ id: queryParams.project_id })
              fetchProjectActivity({ id: queryParams.project_id })
            }
            emit('refetch-data')
            emit('update:is-maintenance-handler-sidebar-active', false)
          })
      loading.value = false
    }

    const onNoRenewMaintenance = async () => {
      emit('refetch-data')
      emit('update:is-maintenance-handler-sidebar-active', false)
    }

    const onDelete = async () => {
      const queryParams = { id: maintenanceData.value.id, project_id: maintenanceData.value.project_id }
      await deleteMaintenance(queryParams)
          .then(async response => {
            fetchNotifications()

            if (store.hasModule('project')) {
              fetchProjectMaintenances({ id: queryParams.project_id })
              fetchProjectUsers({ id: queryParams.project_id })
              fetchProjectActivity({ id: queryParams.project_id })
            }
            emit('refetch-data')
            emit('update:is-maintenance-handler-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(maintenanceData.value)

    const taskDoneClicked = async (taskId, event) => {
      const params = []
      params.project_id = maintenanceData.value.project_id
      params.task_id = taskId
      params.status = event ? 'done' : 'not done'

      await taskDone(params)
        .then(async response => {
          emit('refetch-data')
        })
    }

    const materialCheckedClicked = (materialId, event) => {
      const params = []
      params.project_id = maintenanceData.value.project_id
      params.material_id = materialId
      params.status = event ? 'checked' : 'unchecked'

      checkMaterial(params)
    }

    onMounted(async () => {
      materialsList.value = await fetchMaterialsSelect()
    })

    return {
      propProjectId,
      maintenanceData,
      isMaintenanceCompleteOverlayActive,
      isMaintenanceRenewOverlayActive,
      newTasks,
      newMaterials,
      maintenanceTypes,
      onCompleteMaintenance,
      onFinishMaintenance,
      maintenanceRenewData,
      onRenewMaintenance,
      onNoRenewMaintenance,
      onSubmit,
      onDelete,

      refFormObserver,
      getValidationState,
      resetForm,
      repeatTask,
      removeItem,
      removeTask,
      repeatMaterial,
      removeMaterial,
      removeMaterialItem,
      usersMaintenanceSelected,
      loading,
      materialsList,
      taskDoneClicked,
      materialCheckedClicked,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
